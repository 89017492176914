import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import WOW from 'wowjs';
import { Parallax, useParallax } from 'react-scroll-parallax';
export default function About() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading'>About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">About us</li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>
                        <h3 style={{ color: '#444449', fontWeight: '200' }}>
                            Welcome to Unnati Skils
                        </h3>
                        <p>
                            At Unnati Skils (Powered by Mithpan Educations LLP ), we are dedicated to empowering
                            communities and eradicating poverty by providing innovative business ideas and vocational
                            training. Our mission is to create a sustainable and inclusive society where everyone has the
                            opportunity to thrive.
                        </p>
                        <h3 style={{ color: '#444449', fontWeight: '200' }}>
                            Our Mission
                        </h3>
                        <p>We aim to uplift underprivileged families by equipping them with the Skils and knowledge
                            needed to start and grow their own businesses. By doing so, we help them achieve financial
                            independence and improve their quality of life. </p>

                        <h3 style={{ color: '#444449' }}>
                            <b>What We Offer</b>
                        </h3>
                        <p>
                            <b>1. Voca onal Training:</b> Comprehensive training programs covering essen al Skils such as spoken English, life Skils, basic computer knowledge, and
                            voca onal training tailored to educa onal backgrounds.
                        </p>
                        <p><b>2. Business Ideas:</b> Innova ve and scalable business ideas in key sectors like agriculture, healthcare, and energy, designed to bridge the rural-urban
                            economic divide. </p>
                        <p><b>3. Mentorship and Support:</b> Con nuous support through mentoring and funding to help aspiring entrepreneurs launch and sustain their ventures.</p>
                        <p><b>4. Execu on of Plain:</b> Execu on of the training is sole responsibility of the Personnel who take the training.</p>
                        <p><b>5. Profit Sharing:</b>  Once your business is run you have to share the 10% of the profit.</p>
                        <p><b>6. Income Commitment:</b>  We will only responsible for your income if you buy the material from the supplier who are under Unna network.</p>
                        <p><b>7. Training Mar al:</b> The training will be done as and when no fied there will be not recording sessions.</p>
                        <p><b>8. Investment:</b> A Minimum investment need to be done to successfully run the business which is be done Solly by the you.</p>

                        <h3><b>Success Stories</b></h3>
                        <ul>
                            <li><b>Jayapradha:</b> “UNNATI is my second home and has helped me overcome many challenges. It has given me a new start in life.”</li>
                            <li><b> Sachin:</b> “UNNATI means a brand new life. I have learned many valuable Skils here that have transformed my life.”</li>
                            <li><b>Navyashree:</b> “UNNATI taught me life Skils, basic manners, and computer Skils, which have been crucial in my personal and professional growth.”</li>
                        </ul>
                        <h3><b>Join Us </b></h3>
                        <ul>
                            <li>Be a part of our journey to create a better future for everyone. Together, we can make a significant impact and help eradicate poverty through
                                empowerment and education</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className='padding-left-right marketing-section'>
                <div className='container px-0'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>IMPACT ANALYSIS</h3>
                        <h2 className='heading' style={{ color: '#a35c19' }}>MEASURING THE IMPACT OF BUSINESS EDUCATION</h2>
                        <p>Enhancing Employment, Success, and Income through Education</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <table class="table">
                                <thead style={{ backgroundColor: '#ee930c' }}>
                                    <tr>
                                        <th scope="col">Metrics</th>
                                        <th scope="col">Values</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Employment Rates</td>
                                        <td>Increased by 25%</td>
                                    </tr>
                                    <tr>
                                        <td>Business Success Rates</td>
                                        <td>Improved by 30%</td>
                                    </tr>
                                    <tr>
                                        <td>Income Levels</td>
                                        <td>Raised by 40%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section >

            <section className='bottom padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>EDUCATION CHALLENGES SWOT</h3>
                        <h2 className='heading' style={{ color: '#a35c19' }}>CHALLENGES IN IMPLEMENTING BUSINESS EDUCATION</h2>
                        <p>Addressing Key Hurdles for Effective Business Education Programs</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp' ><b >Accessibility: Ensuring equal access to business education in urban and rural areas</b> </h6>
                            <p className='paragraph wow fadeInUp' >The involves overcoming barriers such as lack of infrastructure and connectivity to deliver education to all segments of society</p>
                        </div>

                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp'><b >Quality Maintenance: Upholding high standards in business education delivery</b> </h6>
                            <p className='paragraph wow fadeInUp' >Sustaining quality involves continuous improvement, faculty development, and alignment with industry requirements to produce skilled graduates.</p>

                        </div>
                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp'><b >Funding Challenges: Securing financial support for sustainable educational initiatives</b> </h6>
                            <p className='paragraph wow fadeInUp' >Addressing funding gaps is crucial to sustain educational programs, scholarships, and infrastructure development, ensuring long-term success.</p>
                        </div>
                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp'><b>Awareness Campaigns: Promoting benefits of business education</b> </h6>
                            <p className='paragraph wow fadeInUp'>Increasing awareness among communities, students, and policymakers to emphasize the significance of business education in enhancing employability and economic growth </p>
                        </div>
                    </div>

                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>▲ BUSINESS EDUCATION IMPACT</h3>
                        <h2 className='heading' style={{ fontSize: '31px', color: '#a35c19' }}>IMPACT OF BUSINESS EDUCATION ON POVERTY REDUCTION</h2>
                        <p>Transforming Lives through Business Education</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp' ><b >Entrepreneurship</b> </h6>
                            <p className='paragraph wow fadeInUp' >Encourages innovation and fosters the creation of new businesses, driving economic growth.</p>
                        </div>

                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp'><b >Economic Growth</b> </h6>
                            <p className='paragraph wow fadeInUp' >Stimulates local economies by fostering business development, creating sustainable financial opportunities.</p>

                        </div>
                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp'><b >Employment</b> </h6>
                            <p className='paragraph wow fadeInUp' >Equips individuals with in-demand Skils, reducing unemployment rates and enhancing workforce productivity.</p>
                        </div>
                        <div className='col-lg-6' style={{ padding: '5px' }}>
                            <h6
                                className='sub-heading wow fadeInUp'><b>Empowerment</b> </h6>
                            <p className='paragraph wow fadeInUp'>economic future, promoting self- sufficiency and financial independence. </p>
                        </div>
                    </div>

                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>▲ IMPACT & IMPORTANCE</h3>
                        <h2 className='heading' style={{ fontSize: '31px', color: '#a35c19' }}>THE ROLE OF BUSINESS EDUCATION</h2>
                        <p>Empowering Individuals for Economic Growth</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section' style={{ background: '#d77f26', padding: '16px', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff' }}>Developing Entrepreneurial Skils</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Business education helps individuals acquire the necessary Skils to initiate and manage businesses effectively. </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='left-section' style={{ background: '#a34200', padding: '16px', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff' }}>Fostering Job Creation</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>By imparting business knowledge, education enables job creation through entrepreneurship and business expansion. </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='left-section' style={{ background: '#6b130f', padding: '16px', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff' }}>Driving Economic Growth</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Equipping individuals with business acumen contributes to overall economic growth and development. </p>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='left-section' style={{ background: '#17616e', padding: '16px', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff' }}>Enhancing Innovation</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Business education fosters innovation and creativity, leading to new business ideas and solutions. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}
