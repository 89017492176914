import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'
import Inquiry from '../component/Inquiry';

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading'>Contact Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom padding-left-right '>
                {/* <div className="contact-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-detail">
                                    <h3 className="sub-heading wow fadeInUp">Contact us</h3>
                                    <h2 className='wow fadeInUp heading'>Get in Touch</h2>
                                    <div className='call-to-action'>
                                        <a href='tel:915757244789' className='wow fadeInUp'><i class="fa-solid fa-phone"></i> +91-01169270721</a>

                                        <a href="mailto:support@unnatiskils.com" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> support@unnatiskils.com</a>
                                    </div>
                                    <div className='social_media'>
                                        <p>Follow us:</p>
                                        <ul>
                                            <li>
                                                <a href="https://www.linkedin.com/in/unnati-skils-602a97323/" target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.pinterest.com/Unnatiskils" target='_blank'><i class="fa-brands fa-pinterest-p" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://x.com/unnatiskils" target='_blank'><i class="fa-brands fa-xing" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/@Unnatiskils" target='_blank'><i className="fa-brands fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form'>
                                        <form action="#" className="contFrm" method="POST">
                                            <Label className='wow fadeInUp' data-wow-delay="0.1s">Your Name*</Label>
                                            <div className="row">
                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
                                                    <input type="text" placeholder="First name" className="inptFld" required />
                                                </div>

                                                <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                                    <input type="text" placeholder="Last name" className="inptFld" required />
                                                </div>
                                            </div>

                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    <input type="email" placeholder="example@gmail.com" className="inptFld" required />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.6s">Your Message*</Label>
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.7s">
                                                    <textarea className="inptFld" rows="5" cols="" placeholder="Your Message..." required></textarea>
                                                </div>
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Inquiry />
            </section>
        </>
    )
}
