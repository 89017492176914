import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import Inquiry from '../../component/Inquiry';
import Whychooseus from '../../component/Whychooseus';
export default function ServiceDetails() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Service Details</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='service-detail-left-side'>
                                <h3 className='sub-heading'><i class="fa fa-long-arrow-right"></i> OUR SERVICES</h3>
                                <h2 className='heading'>SEO Optimization Service</h2>
                                <p className=''>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at accumsan lorem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus varius mauris magna, nec dapibus ante ullamcorper nec. Aenean sodales purus fringilla sapien tristique, at rutrum neque porta.
                                </p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={'https://askproject.net/forrel/wp-content/uploads/sites/101/2022/09/two-young-business-partners-working-together-in-wo-99ZJ87L.jpg'} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={'https://askproject.net/forrel/wp-content/uploads/sites/101/2022/09/two-young-business-partners-working-together-in-wo-99ZJ87L.jpg'} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={'https://askproject.net/forrel/wp-content/uploads/sites/101/2022/09/two-young-business-partners-working-together-in-wo-99ZJ87L.jpg'} />
                                        </div>
                                    </div>
                                </div>
                                <h3 className='sub-heading'>This is how we work</h3>
                                <p>Donec blandit nunc eu gravida pulvinar. Fusce placerat, orci at dignissim dapibus, quam dui vehicula lacus, a pulvinar augue justo eu sapien. Nunc luctus eget turpis sed cursus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse molestie ac lorem sed faucibus.</p>
                                <ul>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                    <li><i class="fa-solid fa-check"></i> Pellentesque efficitur nisl nec hendrerit ullamcorper. Donec placerat.</li>
                                </ul>
                                <div className='faq-section-inner'>
                                    <h4 className='sub-heading'>Frequently Asked Questions</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Vestibulum massa ipsum, mollis sit amet lorem ut.</p>
                                    <div class="accordion wow fadeInUp" id="accordionExample">
                                        <div className='faq-box'>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Accordion Item #1
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Accordion Item #2
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Accordion Item #3
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='right-section-service'>
                                <h4 className='sub-heading'>Service Include</h4>
                                <p>Nam velit est, luctus vel porttitor vitae, facilisis quis odio. Mauris ultrices.</p>
                                <ul>
                                    <li><i aria-hidden="true" class="fas fa-circle"></i> Free Consultation</li>
                                    <li><i aria-hidden="true" class="fas fa-circle"></i> Full Data Analysis</li>
                                    <li><i aria-hidden="true" class="fas fa-circle"></i> Maintenance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
