import React, { useEffect, useRef, useState } from 'react'
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import Inquiry from '../../component/Inquiry';
import { get } from '../helper/helper_api';

export default function Service() {
    const swiperRef1 = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const getData = () => {
        get("/course/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setData(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    const parseTitle = (name) => {
        const url = name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return url;
    }


    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Our Courses</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Courses</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'> OUR ALL COURSES</h3>
                        <h2 className='heading'>Most Popular Courses</h2>
                    </div>
                    <div className='row'>
                        {data.map((item, index) => (
                            <div className='col-lg-3'>
                                <div className='service_box'>
                                    <Link to={`/services/${parseTitle(item?.name)}/${item?._id}`}>
                                        <div className='thumb-img'>
                                            <img src={item?.thumb} alt='ser-1' />
                                        </div>
                                    </Link>
                                    <div className='service-detail-box'>
                                        <h4>{item?.name}</h4>
                                        <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                            <div class="tp-course-rating-star">
                                                <p>5.0<span> /5</span></p>
                                                <div class="tp-course-rating-icon">
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="tp-course-pricing home-2">
                                                <del>₹{item?.offprice}</del>
                                                <span>₹{item?.price}</span>
                                            </div>
                                        </div>
                                        <Link to={`/services/${parseTitle(item?.name)}/${item?._id}`}>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/2.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Starting seo as your home
                                        based business</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹826.00</del>
                                            <span>₹754.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/3.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹926.00</del>
                                            <span>₹654.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/consumer-marketing"}>
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/4.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹1026.00</del>
                                            <span>₹854.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/5.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹269.00</del>
                                            <span>₹154.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/7.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹626.00</del>
                                            <span>₹454.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/8.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹426.00</del>
                                            <span>₹284.00</span>
                                        </div>
                                    </div>
                                    <Link to='services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/course/course-thumb-1.jpg" alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹460.00</del>
                                            <span>₹354.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <Inquiry />
        </>
    )
}
