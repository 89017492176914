import React, { useEffect, useRef, useState } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import { get } from '../helper/helper_api';
import { useParams } from 'react-router-dom';

export default function ConsumerMarketing() {
    const swiperRef1 = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const params = useParams();


    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    useEffect(() => {
        if (params) {
            getData(params?.id);
        }
    }, [params]);

    const getData = (id) => {
        get(`/course/details?courseId=${id}`)
            .then(json => {
                console.log('response from get blog list', json);
                if (json?.statusCode == 200) {
                    setData(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting blog list', error);
            })
    }


    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Digital Marketing</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item"><Link to="/services">Courses</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Digital Marketing</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            {data.map((item, index) => (
                                <div className='service-detail-left-side'>
                                    <h3 className='sub-heading'> ABOUT COURSES</h3>
                                    <h2 className='heading'>Digital Marketing</h2>
                                    <p className=''>
                                        <strong>{item?.name}</strong>
                                    </p>
                                    <p>This course is aimed at people interested in UI/UX Design. We’ll start from the very
                                        beginning and work all the way through, step by step. If you already have some UI/UX
                                        Design experience but want to get up to speed using Adobe XD then this course is perfect
                                        for you too!</p>
                                    <div className='three-images-container'>
                                        <div className='first-img-box'>
                                            {/* {item?.images.length > 0 && (
                                                <div className='first-img-box'>
                                                    <img src={item.images[0]} />
                                                </div>
                                            )} */}
                                            {item?.images.map((img, index) => (
                                                <div className='child-box' key={index}>
                                                    <img src={img} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className='second-img-box'>
                                            <div className='child-box'>
                                                {/* {item?.images.map((img, index) => (
                                                    <div className='child-box' key={index}>
                                                        <img src={img} />
                                                    </div>
                                                ))} */}
                                            </div>
                                            <div className='child-box'>
                                                {/* <img src={require('../../assets/images/services/Consumer-Marketing/3.jpg')} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{item?.desc}</p>
                                    {/* <ul>
                                        <li><i className="fa-solid fa-check"></i>Understanding Your Audience: We begin by conducting in-depth research to understand your target audience's demographics, psychographics, and behaviors, enabling us to tailor our strategies to their specific needs and preferences.
                                            Crafting Compelling Campaigns: Based on our insights, we develop creative and innovative campaigns designed to resonate with your audience, leveraging various channels and touchpoints to maximize reach and impact.</li>
                                        <li><i className="fa-solid fa-check"></i>Engaging Experiences: We believe in the power of experiences to create lasting connections with consumers. Whether through live events, interactive activations, or immersive digital content, we strive to create memorable moments that foster brand engagement and loyalty.</li>
                                        <li><i className="fa-solid fa-check"></i>Measuring Impact: We continuously monitor and analyze the performance of our campaigns, tracking key metrics and KPIs to evaluate effectiveness and optimize strategies for ongoing success.</li>
                                        <li><i className="fa-solid fa-check"></i>Driving Results: Our ultimate goal is to drive tangible results for your business, whether it's increased brand awareness, customer acquisition, or sales growth. We are committed to delivering measurable outcomes that align with your objectives and drive long-term success.</li>
                                    </ul> */}

                                </div>
                            ))}
                            {/* <div className='service-detail-left-side'>
                                <h3 className='sub-heading'> ABOUT COURSES</h3>
                                <h2 className='heading'>Digital Marketing</h2>
                                <p className=''>
                                    <strong>Bigener Adobe Illustrator for Graphic Design</strong>
                                </p>
                                <p>This course is aimed at people interested in UI/UX Design. We’ll start from the very
                                    beginning and work all the way through, step by step. If you already have some UI/UX
                                    Design experience but want to get up to speed using Adobe XD then this course is perfect
                                    for you too!</p>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/services/Consumer-Marketing/1.jpg')} />
                                    </div>
                                    <div className='second-img-box'>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Consumer-Marketing/2.jpg')} />
                                        </div>
                                        <div className='child-box'>
                                            <img src={require('../../assets/images/services/Consumer-Marketing/3.jpg')} />
                                        </div>
                                    </div>
                                </div>
                                <h3 className='sub-heading'>This is how we work</h3>
                                <p>At Brand Street Integrated, our consumer marketing strategies are meticulously crafted to resonate with target audiences, creating meaningful connections that drive brand loyalty and engagement. Through a deep understanding of consumer behavior and preferences, we develop tailored campaigns that captivate, inspire, and ultimately compel action. Whether it's through immersive experiences, captivating storytelling, or strategic digital engagement, we are dedicated to delivering impactful results that elevate your brand and leave a lasting impression on consumers.</p>
                                <ul>
                                    <li><i className="fa-solid fa-check"></i>Understanding Your Audience: We begin by conducting in-depth research to understand your target audience's demographics, psychographics, and behaviors, enabling us to tailor our strategies to their specific needs and preferences.
                                        Crafting Compelling Campaigns: Based on our insights, we develop creative and innovative campaigns designed to resonate with your audience, leveraging various channels and touchpoints to maximize reach and impact.</li>
                                    <li><i className="fa-solid fa-check"></i>Engaging Experiences: We believe in the power of experiences to create lasting connections with consumers. Whether through live events, interactive activations, or immersive digital content, we strive to create memorable moments that foster brand engagement and loyalty.</li>
                                    <li><i className="fa-solid fa-check"></i>Measuring Impact: We continuously monitor and analyze the performance of our campaigns, tracking key metrics and KPIs to evaluate effectiveness and optimize strategies for ongoing success.</li>
                                    <li><i className="fa-solid fa-check"></i>Driving Results: Our ultimate goal is to drive tangible results for your business, whether it's increased brand awareness, customer acquisition, or sales growth. We are committed to delivering measurable outcomes that align with your objectives and drive long-term success.</li>
                                </ul>

                            </div> */}
                        </div>
                        <div className='col-lg-4'>
                            <div className='right-section-service'>
                                {/* <h4 className='sub-heading'>Services</h4> */}
                                {/* <div className="tp-course-details-2-widget"> */}
                                <div className="tp-course-details-2-widget-thumb p-relative">
                                    <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/course/details/course.jpg" alt="" />
                                    <a className="popup-video" href="https://www.youtube.com/watch?v=EW4ZYb3mCZk&amp;t=1s"><span><svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 1.83167C0 1.0405 0.875246 0.562658 1.54076 0.990487L12.6915 8.15882C13.3038 8.55246 13.3038 9.44754 12.6915 9.84118L1.54076 17.0095C0.875246 17.4373 0 16.9595 0 16.1683V1.83167Z" fill="#031F42"></path>
                                    </svg></span></a>
                                </div>
                                <div className="tp-course-details-2-widget-content">
                                    <div className="tp-course-details-2-widget-price">
                                        <span>₹120.99 </span>
                                        <del>₹145.99 </del>
                                    </div>
                                    <div className="tp-course-details-2-widget-btn">
                                        <Link className="active" to="/enrolment" style={{ textDecoration: 'none' }}>Buy Course</Link>
                                        <p>30-Day Money-Back Guarantee</p>
                                    </div>

                                    <div className="tp-course-details-2-widget-list">
                                        <h5>This course includes:</h5>

                                        <div className="tp-course-details-2-widget-list-item-wrapper">

                                            <div className="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                                                <span> <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 13V5.5" stroke="#4F5158" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M10 13V1" stroke="#4F5158" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M1 13V10" stroke="#4F5158" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg> Skill Level</span>
                                                <span>Beginner</span>
                                            </div>
                                            <div className="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                                                <span> <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5Z" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M1 8.5H15" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M7.99727 1.5C9.74816 3.41685 10.7432 5.90442 10.7973 8.5C10.7432 11.0956 9.74816 13.5832 7.99727 15.5C6.24637 13.5832 5.25134 11.0956 5.19727 8.5C5.25134 5.90442 6.24637 3.41685 7.99727 1.5Z" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg> Language</span>
                                                <span>English</span>
                                            </div>
                                            <div className="tp-course-details-2-widget-list-item d-flex align-items-center justify-content-between">
                                                <span> <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M1.06836 6.18286H13.5451" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path opacity="0.4" d="M10.4102 8.91675H10.4194" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path opacity="0.4" d="M7.30273 8.91675H7.312" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path opacity="0.4" d="M4.1875 8.91675H4.19676" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path opacity="0.4" d="M10.4102 11.6375H10.4194" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path opacity="0.4" d="M7.30273 11.6375H7.312" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path opacity="0.4" d="M4.1875 11.6375H4.19676" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M10.1289 1V3.30355" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M4.47656 1V3.30355" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2668 2.10535H4.33967C2.28399 2.10535 1 3.2505 1 5.35547V11.6902C1 13.8283 2.28399 14.9999 4.33967 14.9999H10.2603C12.3225 14.9999 13.6 13.8481 13.6 11.7432V5.35547C13.6065 3.2505 12.329 2.10535 10.2668 2.10535Z" stroke="#4F5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg> Deadline</span>
                                                <span>30 Nov 2024</span>
                                            </div>

                                            <div className="tp-course-details-2-widget-share d-flex align-items-center justify-content-between">
                                                <a className="share" href="#"><span><svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.5023 5.2C12.6621 5.2 13.6023 4.2598 13.6023 3.1C13.6023 1.9402 12.6621 1 11.5023 1C10.3425 1 9.40234 1.9402 9.40234 3.1C9.40234 4.2598 10.3425 5.2 11.5023 5.2Z" stroke="#5169F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M3.1 10.1001C4.2598 10.1001 5.2 9.15994 5.2 8.00014C5.2 6.84035 4.2598 5.90015 3.1 5.90015C1.9402 5.90015 1 6.84035 1 8.00014C1 9.15994 1.9402 10.1001 3.1 10.1001Z" stroke="#5169F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M11.5023 15C12.6621 15 13.6023 14.0598 13.6023 12.9C13.6023 11.7403 12.6621 10.8 11.5023 10.8C10.3425 10.8 9.40234 11.7403 9.40234 12.9C9.40234 14.0598 10.3425 15 11.5023 15Z" stroke="#5169F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M4.91406 9.05701L9.69506 11.843" stroke="#5169F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M9.68806 4.15723L4.91406 6.94322" stroke="#5169F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg></span> Share this course</a>
                                                <a className="coupon" href="#">Apply coupon</a>
                                            </div>
                                            <div className="tp-course-details-2-widget-search p-relative">
                                                <form action="#">
                                                    <input type="text" className='form-control' placeholder="Enter Coupon Code" />
                                                    <button type="submit">Apply</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='about-section-4 bottom_100px padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div class="tp-course-details-2-related-heading pt-80">
                        <h3 class="tp-course-details-2-related-title">Related Courses</h3>
                        <p>10,000+ unique online course list designs</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/5.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹269.00</del>
                                            <span>₹154.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/7.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹626.00</del>
                                            <span>₹454.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../../assets/images/services/course/8.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹426.00</del>
                                            <span>₹284.00</span>
                                        </div>
                                    </div>
                                    <Link to='services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/course/course-thumb-1.jpg" alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹460.00</del>
                                            <span>₹354.00</span>
                                        </div>
                                    </div>
                                    {/* <div class="tp-course-btn home-2">
                                        <Link to='/services/printing-and-posm-solutions'>Preview this Course</Link>
                                    </div> */}
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
