import React, { useState, useRef } from 'react'
import { Label } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { post, get } from '../pages/helper/helper_api';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';


export default function Inquiry() {

    const [formData, setFormData] = useState({});

    const form = useRef();

    const handleValidSubmit = (e, v) => {

        const body = {
            ...v,
        };

        post('/enrolment', body)
            .then((res => {
                if (res?.statusCode == 200) {
                    toast.success(res?.message);
                    form.current.reset();
                } else {
                    toast.error(res?.error);
                }
            }))
            .catch(error => {
                console.log('error while login', error);
                toast.error('Something went wrong');
            })
    }

    return (
        <>
            {/* Contact Us */}
            <section className='inner-banner'>
                <h1 className='heading'>Enrollment</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Enrolment</li>
                    </ol>
                </nav>
            </section>
            <section className='bottom top padding-left-right  fontact-us-home'>
                <div className="contact-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className='enrolment-user'>
                                    <img src='https://media.istockphoto.com/id/1439915602/photo/speech-bubble-and-megaphone-that-says-open-enrollment.jpg?s=612x612&w=0&k=20&c=PrzXeljX9cwia3Jclo8jmB9Mooo0JTN6qfohg9813xQ=' alt='' />

                                </div>
                                {/*   <div className="contact-detail">
                                    <h3 className="sub-heading wow fadeInUp">Contact us</h3>
                                    <h2 className='wow fadeInUp heading'>Get in Touch</h2>
                                    <div className='call-to-action'>
                                        <a href='tel:-000-123-478' className='wow fadeInUp'><i class="fa-solid fa-phone"></i> +91-01169270721</a>

                                        <a href="mailto:hi@.co" className='wow fadeInUp'><i class="fa-solid fa-envelope"></i> support@unnatiskils.com</a>
                                    </div>
                                    <div className='social_media'>
                                        <p>Follow us:</p>
                                        <ul>
                                            <li>
                                                <a href="https://www.linkedin.com/in/unnati-skils-602a97323/" target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.pinterest.com/Unnatiskils" target='_blank'><i class="fa-brands fa-pinterest-p" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://x.com/unnatiskils" target='_blank'><i class="fa-brands fa-xing" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/@Unnatiskils" target='_blank'><i className="fa-brands fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                            </div>*/}
                            </div>
                            <div className="col-lg-6">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form'>
                                        {/* <form action="#" className="contFrm" method="POST"> */}
                                        <AvForm ref={form} onValidSubmit={handleValidSubmit}>
                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Name*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                    <AvField name="name" placeholder="Enter name..." className="inptFld" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                    <AvField name="email" placeholder="example@gmail.com..." className="inptFld" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Mobile*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                    <AvField name="mobile" placeholder="Enter mobile.." className="inptFld" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>

                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SUBMIT<span>SUBMIT</span></button>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
