
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Parallax, useParallax } from 'react-scroll-parallax';

export default function PrivacyPolicy() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>AGREEMENT</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">AGREEMENT </li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <h3 className='sub-heading'>AGREEMENT
                        BETWEEN SERBIA AND MONTENEGRO
                        AND THE STATE OF ISRAEL
                        FOR THE RECIPROCAL PROMOTION AND
                        PROTECTION OF INVESTMENTS</h3>

                    <div className='row'>
                        <p>Serbia and Montenegro and the State of Israel (hereinafter: the "Contracting Parties"), </p>
                        <p>DESIRING to intensify economic cooperation to the mutual benefit of both countries;</p>
                        <p>INTENDING to create favorable conditions for greater investments by investors of either Contracting Party in the territory of the other Contracting Party; and
                            RECOGNIZING that the reciprocal promotion and protection of investments on the basis of the present Agreement will be conductive to the stimulation of individual business initiative and will increase prosperity in both States,
                        </p>
                        <h4><b>HAVE AGREED as follows:</b></h4>

                        <h3>Definitions</h3>
                        <p><b>For the purposes of the present Agreement:</b></p>
                        <p>1.The term "investments" shall mean any kind of assets, invested in accordance with the laws and regulations of the Contracting Party in whose territory the investment is made including, but not limited to:</p>
                        <ul>
                            <ol type="A">
                                <li>
                                    Movable and immovable property, as well as any other rights in rem;
                                </li>
                                <li>Rights derived from stocks, shares, bonds, debentures and other kinds of interests in companies;</li>
                                <li>Claims to money and any other claim having an economic value;</li>
                                <li>Rights in the field of intellectual property, including patents, trade marks, geographical indications, industrial designs, technical processes, copyrights and related rights, undisclosed business information, trade secrets, goodwill and know-how, topographies of integrated circuits and plant-breeders rights; </li>
                                <li>Business concessions conferred by law or under contract, including concessions to search for, cultivate, extract or exploit natural resources.</li>
                            </ol>
                        </ul>
                        <p>2.The Contracting Party in whose territory the investment is made shall be called the "Host Contracting Party".</p>
                        <p>3.	The term "returns" shall mean the amount yielded by an investment including, but not limited to: dividends, profits, sums received from the total or partial liquidation of an investment, interest, capital gains, royalties or fees.</p>
                        <p>4.	The provisions of this Agreement relating to investments shall apply to the reinvestment of the returns of an investment, which shall be granted the same treatment granted to the original investment, if the reinvestment is effected in accordance with the legislation of the Host Contracting Party. A change in the form of the investment or a change in the form of the reinvestment shall not affect their character as investments if that change is effected in accordance with the legislation of the Host Contracting Party.</p>
                    </div>
                </div>
            </section>

        </>
    )
}
