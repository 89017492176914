import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../component/Header";
import Footer from "../component/Footer";
import About from "../pages/About";
import Service from "../pages/Service/Service";
import ServiceDetails from "../pages/Service/ServiceDetails";
import ConsumerMarketing from "../pages/Service/ConsumerMarketing";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Contact from "../pages/Contact";
import BootNavBar from "../component/BootNavbar";
import Agreement from '../pages/Agreement';
import Signup from "../component/Signup";
import Enrolment from '../component/Enrollment';
import Team from '../pages/Team';


export default function Router() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup function to clear the timer
    }, []);
    const [headerData, setHeaderData] = useState([]);
    // const [popularData, setPopularData] = useState([]);
    // const [filters, setFilters] = useState({ dest: [], exp: [], who: [], month: [] });
    // const [isVcard, setIsVcard] = useState(false);

    return (
        <>
            <BrowserRouter>

                {/* <Header setHeaderData={setHeaderData} /> */}
                <BootNavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Service />} />
                    <Route path="/services/:title/:id" element={<ConsumerMarketing />} />
                    <Route path="/service-detail" element={<ServiceDetails />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/agreement" element={<Agreement />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/enrolment" element={<Enrolment />} />
                    <Route path="/team" element={<Team />} />
                </Routes>
                <Footer headerData={headerData} />
            </BrowserRouter >
        </>
    );
}