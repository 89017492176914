import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { post } from '../pages/helper/helper_api';
import { toast } from 'react-toastify';


export default function Footer() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  const form = useRef();

  useEffect(() => {
    const updateScrollPercentage = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (scrollPx / winHeightPx) * 100;

      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', updateScrollPercentage);

    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  useEffect(() => {
    let time = null;
    const getData = async () => {
      const item = await localStorage.getItem('policy');
      console.log('policy from storage', item);
      if (!item)
        time = setTimeout(() => {
          setIsDisclaimer(true)
        }, 3000);
    }
    getData();
    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setIsDisclaimer(true);
      } else {
        setIsDisclaimer(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleOkayDisclaimer = () => {
    setIsDisclaimer(false);
    localStorage.setItem('policy', 'agreed');
  }

  const handleValidSubmit = (e, v) => {
    const body = {
      ...v
    }
    post('/subc', body)
      .then((res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          form.current.reset();
        } else {
          toast.error(res?.error);
        }
      }))
      .catch(error => {
        console.log('error while login', error);
        toast.error('Something went wrong');
      })
  }

  return (
    <footer className='footer top padding-left-right'>

      <div className="container-fluid p-0">
        <div className='row'>
          <div className="footer-col col-lg-3 col-md-6">
            <div className='footer-logo'>
              <img src={require("../assets/images/icons.png")} alt="" />
            </div>
            {/* <h4> Unnati Skils</h4> */}
            <p className='footer-address-para'>D-11 Sector 6 Noida, Utter Pradesh<br />
              <span><a href="tel:01169270721">+91-01169270721</a></span>
              <span> <a href="mailto:support@unnatiskils.com">support@unnatiskils.com</a></span>
            </p>

            {/* <ul className='mt-0'>
              <li><a href="tel:01169270721">+91-01169270721</a></li>
              <li><a href="mailto:support@unnatiskils.com">support@unnatiskils.com</a></li>
            </ul> */}
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4>Quicklink</h4>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/team">Team</Link></li>
              <li><Link to="/agreement">Agreement</Link></li>
              <li><Link to="/privacy-policy">Sales Agreement</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">
            <h4>Popular Courses</h4>
            <ul>
              <li><Link to="/services/consumer-marketing">Digital Marketing</Link></li>
              <li><Link to="/services/consumer-marketing">Trade Marketing</Link></li>
              <li><Link to="/services/consumer-marketing">Rural Marketing</Link></li>
              <li><Link to="/services/consumer-marketing ">Web Development</Link></li>
            </ul>
          </div>
          <div className="footer-col col-lg-3 col-md-6">

            <div class="col-sm-12">
              <AvForm ref={form} onValidSubmit={handleValidSubmit} className='footer-form'>
                <AvField name="email" placeholder="Email" className="inptEmail" />
                <button className='btn footer-btn btn-class'>Subscribe<span>Subscribe</span></button>
                <p className='mt-0 pt-0'>* we respact your privacy</p>

              </AvForm>
            </div>

            <div className="social-links">
              <li>
                <a href="https://www.linkedin.com/in/unnati-skils-602a97323/" target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
              </li>
              <li>
                <a href="https://www.pinterest.com/Unnatiskils" target='_blank'><i class="fa-brands fa-pinterest-p" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="https://x.com/unnatiskils" target='_blank'><i class="fa-brands fa-xing" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="https://www.youtube.com/@Unnatiskils" target='_blank'><i className="fa-brands fa-youtube"></i></a>
              </li>
            </div>
          </div>
        </div>


      </div>
      <div className='container-fluid'>
        <div className='footer-copyright row'>
          <div className='col-lg-6 p-0'>
            <div className='copy-right-p'>
              <p> &copy; 2024 Unnati Skils All rights reserved Powered By Mithpan Education. </p>
            </div>
          </div>
          {/* <div className='col-lg-3 p-0'>
            <div className='privacy-policy'>
              <p><Link to='/privacy-policy'>Sale Agreement </Link></p>
            </div>
          </div> */}
          <div className='col-lg-6 p-0'>
            <div className='website-link'>
              <p>Website by <a href='https://holisticecommerce.com/' target='_blank'>Holistic Ecommerce</a></p>
            </div>
          </div>
        </div>
      </div>

      <div class="progress-circle-container">

        <svg viewBox="0 0 36 36" className="circular-chart">
          <path
            className="circle-bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${scrollPercentage}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>

        <div className="uparrow">
          <a href="#"><i className="fa fa-arrow-up"></i></a>
        </div>

      </div>
    </footer>
  )
}


