import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
// import WOW from 'wowjs';

import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import Inquiry from '../component/Inquiry';
import Whychooseus from '../component/Whychooseus';
import FeaturedProject from '../component/FeaturedProject';
import { get } from "./helper/helper_api";

export default function Home() {
    const swiperRef1 = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [cat, setCat] = useState([]);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        getCourseCag();
    }, []);

    const getData = () => {
        get("/course/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setData(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    const getCourseCag = () => {
        get("/course/cat")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setCat(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    const style = {
        visibility: 'visible',
        animationDelay: '0.3s',
        animationName: 'fadeInUp',
    };

    const style1 = {
        visibility: 'visible',
        animationDuration: '1.5s',
        animationDelay: '0.4s',
        animationName: 'bounceIn',
    };

    const parseTitle = (name) => {
        const url = name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return url;
    }

    return (
        <>
            <div className='banner-slider'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className=" home-slider"
                >
                    <SwiperSlide>

                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-1.jpg')} alt='banner' className='web-banner' />
                            <div className='banner-content padding-left-right'>

                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}>Keep Learning </h6>
                                <h1 className='heading'>Best digital Online Courses and Investment</h1>
                                <p className='paragraph'>Acquire global knowledge and build your professional Skils</p>
                                <a href='#' className='btn-class'>
                                    Find Courses
                                    <span>Find Courses </span>
                                </a>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>

                        <div className='banner-section '>

                            <img src={require('../assets/images/crouse-5.jpg')} alt='banner' className='web-banner' />
                            <div className='banner-content padding-left-right'>

                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}>Keep Learning </h6>
                                <h1 className='heading'>Best digital Online Courses and Investment</h1>
                                <p className='paragraph'>Acquire global knowledge and build your professional Skils</p>
                                <a href='#' className='btn-class'>
                                    Find Courses
                                    <span>Find Courses </span>
                                </a>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section '>
                            <img src={require('../assets/images/crouse-1.jpg')} alt='banner' className='web-banner' />
                            <div className='banner-content padding-left-right'>

                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}>Keep Learning </h6>
                                <h1 className='heading'>Best digital Online Courses and Investment</h1>
                                <p className='paragraph'>Acquire global knowledge and build your professional Skils</p>
                                <a href='#' className='btn-class'>
                                    Find Courses
                                    <span>Find Courses </span>
                                </a>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='banner-section '>
                            <img src={require('../assets/images/crouse-2.jpg')} alt='banner' className='web-banner' />
                            <div className='banner-content padding-left-right'>

                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading' style={{ color: 'white' }}>Keep Learning </h6>
                                <h1 className='heading'>Best digital Online Courses and Investment</h1>
                                <p className='paragraph'>Acquire global knowledge and build your professional Skils</p>
                                <a href='#' className='btn-class'>
                                    Find Courses
                                    <span>Find Courses </span>
                                </a>
                            </div>
                        </div>

                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <div className='banner-section '>

                            <img src={require('../assets/images/banner-2.jpg')} className='web-banner' alt='banner-2' />
                            <div className='banner-content padding-left-right'>
                                <div className='dot-img'>
                                    <img src={require('../assets/images/white-dot.png')} alt='white-dot' />
                                </div>
                                <h6 className='sub-heading'>BRAND STREET INTEGRATED </h6>
                                <h1 className='heading'>Empowering Brands<br />
                                    & Turning Audiences<br />
                                    Into Advocates</h1>
                                <p className='paragraph'>Brand Street Integrated empowers brands by fostering deep connections and inspiring action. Through innovative strategies, we cultivate lasting relationships and create enthusiastic brand advocates.</p>
                                <a href='#' className='btn-class'>
                                    Get Started
                                    <span>Get Started</span>
                                </a>
                            </div>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>

            <section className='padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left-section'>
                                <h6
                                    className='sub-heading wow fadeInUp'>Unnati Skils </h6>
                                <h2 className='heading wow fadeInUp'>Introduction to our Education Firm</h2>
                                <p className='paragraph wow fadeInUp'>Welcome to our education firm, dedicated to empowering small entrepreneurs with the knowledge and Skils needed to succeed in today's dynamic business landscape. Our mission is to guide you through the complexities of entrepreneurship, from establishing a strong brand identity to optimizing your supply chain.</p>
                                <p><b> Unnati Skils</b></p>
                            </div>
                        </div>
                        <div className='col-lg-6 right_s' style={{ overflow: 'hidden' }}>

                            <div className='right-section'>

                                <img className='wow slideInRight' src={require('../assets/images/about/1.jpg')} alt='team' />

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* Courses Category */}

            <section className='about-section-4 top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>Top Categories</h3>
                        <h2 className='heading'>Most demanding Categories<span style={{ color: 'rgb(163, 66, 0)' }}></span></h2>
                    </div>
                    <div class="row">
                        {/* {cat.map((item, index) => (
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <a href="#" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                    <div class="tp-category-icon">
                                        <span class="cat-purple"><img src={item?.image} alt="" /></span>
                                    </div>
                                    <div class="tp-category-content">
                                        <h4 class="tp-category-title">{item?.title}</h4>
                                        <span>{item?.sorttitle}</span>
                                    </div>
                                </a>
                            </div>
                        ))} */}
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-green"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">UI/UX Design</h4>
                                    <span>Design with Confident</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-purple"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">Lifestyle</h4>
                                    <span>New Skils, New You</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-blue"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">Business</h4>
                                    <span>Improve your business</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-pink"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">Photography</h4>
                                    <span>Major or Minor</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-l-purple"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">Music</h4>
                                    <span>Control your Wallet</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-yellow"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">Teaching</h4>
                                    <span>High Education Level</span>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <a href="course-categories.html" class="tp-category-item mb-25 wow fadeInUp" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp', textDecoration: 'none' }}>
                                <div class="tp-category-icon">
                                    <span class="cat-lgreen"><img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/icon/category/cat-2-icon-2.svg" alt="" /></span>
                                </div>
                                <div class="tp-category-content">
                                    <h4 class="tp-category-title">Development</h4>
                                    <span>Improve your business</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Courses */}

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'> OUR COURSES</h3>
                        <h2 className='heading'>Most Popular Courses</h2>
                    </div>
                    <div className='row'>
                        {data.map((item, index) => (
                            <div className='col-lg-3'>
                                <div className='service_box'>
                                    <Link to={`/services/${parseTitle(item?.name)}/${item?._id}`}>
                                        <div className='thumb-img'>
                                            <img src={item?.thumb} alt='ser-1' />
                                        </div>
                                    </Link>
                                    <div className='service-detail-box'>
                                        <h4>{item?.name}</h4>
                                        <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                            <div class="tp-course-rating-star">
                                                <p>5.0<span> /5</span></p>
                                                <div class="tp-course-rating-icon">
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="tp-course-pricing home-2">
                                                <del>₹{item?.price}</del>
                                                <span>₹{item?.offprice}</span>
                                            </div>
                                        </div>
                                        <Link to={`/services/${parseTitle(item?.name)}/${item?._id}`}>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/consumer-marketing"}>
                                    <div className='thumb-img'>
                                        <img src={require('../assets/images/services/course/1.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Interior design concepts
                                        Masterclass</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹526.00</del>
                                            <span>₹354.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../assets/images/services/course/2.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Starting seo as your home
                                        based business</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹826.00</del>
                                            <span>₹754.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to="/services/consumer-marketing">
                                    <div className='thumb-img'>
                                        <img src={require('../assets/images/services/course/3.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹926.00</del>
                                            <span>₹654.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Preview this Course <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='service_box'>
                                <Link to={"/services/consumer-marketing"}>
                                    <div className='thumb-img'>
                                        <img src={require('../assets/images/services/course/4.jpg')} alt='ser-1' />
                                    </div>
                                </Link>
                                <div className='service-detail-box'>
                                    <h4>Grow personal financial security
                                        thinking & principles</h4>
                                    <div class="tp-course-rating d-flex align-items-end justify-content-between">
                                        <div class="tp-course-rating-star">
                                            <p>5.0<span> /5</span></p>
                                            <div class="tp-course-rating-icon">
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </div>
                                        <div class="tp-course-pricing home-2">
                                            <del>₹1026.00</del>
                                            <span>₹854.00</span>
                                        </div>
                                    </div>
                                    <Link to='/services/consumer-marketing'>Read more <i class="fa-solid fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='about-section-4 bottom_100px padding-left-right team-section-1'>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="tp-brand-2-content mb-40">
                                <h4 class="tp-brand-2-title">Who will <br />  You Learn
                                    <span style={{ color: 'rgb(163, 66, 0)' }}>With?
                                        {/* <img class="tp-underline-shape-10 wow bounceIn" data-wow-duration="1.5s" data-wow-delay=".4s" src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-3.png" alt="" /> */}
                                    </span>
                                </h4>
                                <p>You can list your partners or instructors's <br /> brands here to show off your site's</p>
                                <div class="tp-brand-2-btn" style={{ marginBottom: '25px' }}>
                                    <a class="tp-btn-round" href="#">View All Patners</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div className='row'>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-1.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-2.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-1.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-2.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-1.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-2.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-1.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-2.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-1.png" alt='delhi' />
                                    </div>
                                </div>
                                <div className='col-lg-2  col-md-3 col-sm-4 col-6'>
                                    <div className='logo-box'>
                                        <img src="https://html.hixstudio.net/acadia-prev/acadia/assets/img/brand/brand-2-logo-2.png" alt='delhi' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bottom padding-left-right' style={{ backgroundColor: '#f0eef7' }}><br />
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="testimonialSwiper"
                >

                    <SwiperSlide>
                        <div className='slider_container'>
                            <div className='slider_3'>
                                <div className='slider-text'>
                                    <div className='slider-icons-img'>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTskaUs54oKnoMjOZAOkXiNE7WcLeQkE4AJKw&s" alt='team1' />
                                    </div>

                                </div>
                                <div className='slider-para wow fadeInUp'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of ‘Ek Khat’. The idea was to get the customer insights personally.
                                </div>

                                <h6 className='wow fadeInUp'>ONKAR NATH</h6>
                                <p className='department wow fadeInUp'>Marketing Manager, Mosaic India Pvt Ltd.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider_container'>
                            <div className='slider_3'>
                                <div className='slider-text'>
                                    <div className='slider-icons-img'>
                                        <img src={require('../assets/images/team1.png')} alt='team1' />
                                    </div>

                                </div>
                                <div className='slider-para wow fadeInUp'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of ‘Ek Khat’. The idea was to get the customer insights personally.                                </div>

                                <h6 className='wow fadeInUp'>RICHA OJHA</h6>
                                <p className='department wow fadeInUp'>Brand Manager, Shell Rimula</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider_container'>
                            <div className='slider_3'>
                                <div className='slider-text'>
                                    <div className='slider-icons-img'>
                                        <img src={require('../assets/images/team1.png')} alt='team1' />
                                    </div>

                                </div>
                                <div className='slider-para wow fadeInUp'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of ‘Ek Khat’. The idea was to get the customer insights personally.                                </div>

                                <h6 className='wow fadeInUp'>AKASH BHARDWAJ</h6>
                                <p className='department wow fadeInUp'>Business Owner, Gurgaon</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider_container'>
                            <div className='slider_3'>
                                <div className='slider-text'>
                                    <div className='slider-icons-img'>
                                        <img src={require('../assets/images/team1.png')} alt='team1' />
                                    </div>

                                </div>
                                <div className='slider-para wow fadeInUp'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of ‘Ek Khat’. The idea was to get the customer insights personally.                                </div>

                                <h6 className='wow fadeInUp'>VIKASH SHARMA</h6>
                                <p className='department wow fadeInUp'>Startup, Faridabad</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider_container'>
                            <div className='slider_3'>
                                <div className='slider-text'>
                                    <div className='slider-icons-img'>
                                        <img src={require('../assets/images/team1.png')} alt='team1' />
                                    </div>

                                </div>
                                <div className='slider-para wow fadeInUp'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of ‘Ek Khat’. The idea was to get the customer insights personally.                                </div>

                                <h6 className='wow fadeInUp'>TARUN VERMA</h6>
                                <p className='department wow fadeInUp'>Director, Delhi</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider_container'>
                            <div className='slider_3'>
                                <div className='slider-text'>
                                    <div className='slider-icons-img'>
                                        <img src={require('../assets/images/team1.png')} alt='team1' />
                                    </div>

                                </div>
                                <div className='slider-para wow fadeInUp'>
                                    "We at Mosaic believe in reaching the core of customer needs. Our agency partner Brand Street aligned with our thought process and came up with the concept of ‘Ek Khat’. The idea was to get the customer insights personally.                                </div>

                                <h6 className='wow fadeInUp'>PRIYA SHARMA</h6>
                                <p className='department wow fadeInUp'>Marketing Manager, Mumbai</p>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </section>

            <section className='padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>SOCIETAL IMPACT</h3>
                        <h2 className='heading'>SOCIETAL IMPAC OF POVERTY</h2>
                        <p>Understanding the Multifaceted Effects of Poverty on Society</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left-section' style={{
                                border: '1px solid rgba(3, 31, 66, 0.1)', padding: '23px 23px 15px 23px', margin: '5px', borderRadius: '20px'
                            }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>Health Issues</b> </h6>
                                <p className='paragraph wow fadeInUp'>Poverty leads to malnutrition, high infant mortality rates, and restricted access to quality healthcare services.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section' style={{
                                border: '1px solid rgba(3, 31, 66, 0.1)', padding: '23px 23px 15px 23px', margin: '5px', borderRadius: '20px'
                            }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>Education Challenges</b> </h6>
                                <p className='paragraph wow fadeInUp'>High proverty levels Contribute to elevated dropout rates, substandard education quality, and insufficient educational resources. </p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section' style={{
                                border: '1px solid rgba(3, 31, 66, 0.1)', padding: '23px 23px 15px 23px', margin: '5px', borderRadius: '20px'
                            }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>Economic Consequences</b> </h6>
                                <p className='paragraph wow fadeInUp'>The presence of poverty hampers productivity, elevates unemployment rates, and restricts overall economic growth. </p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='left-section' style={{
                                border: '1px solid rgba(3, 31, 66, 0.1)', padding: '23px 23px 15px 23px', margin: '5px', borderRadius: '20px'
                            }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>Social Inequality Impact</b> </h6>
                                <p className='paragraph wow fadeInUp'>Poverty fuels discrimination, social exclusion, and a rise in crime rates within communities. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>ROOT CAUSES</h3>
                        <h2 className='heading'>ROOT CAUSES OF POVERTY</h2>
                        <p>Understanding the Underlying Factors Contributing to Poverty in India</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='left-section' style={{ background: '#d77f26', padding: '16px', textAlign: 'center', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff' }}>Lack of Education</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>The limited access to quality education and vocational training hinders individuals from acquiring Skils for economic empowerment. </p>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='left-section' style={{ background: '#a34200', padding: '16px', textAlign: 'center' }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b style={{ color: '#fff' }}>Unemployment</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>High rates of joblessness and underemployement result in financial instability and lack of sustainable income sources.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='left-section' style={{ background: '#6b130f', padding: '16px', textAlign: 'center' }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b style={{ color: '#fff' }}>Helathcare Deficiencies</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>The lack of affordable healthcare services leads to increased helathcare expenses and reduced financial resilience among the impoverished.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='left-section' style={{ background: '#3d1619', padding: '16px', textAlign: 'center' }}>
                                <h6
                                    className='sub-heading wow fadeInUp'><b style={{ color: '#fff' }}>Economic Policies</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Ineffective economic policies coupled with the lack of support for small businesses create barriers to economic growth and Development. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className='padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>POVERTY DISTRIBUTION ANALYSIS</h3>
                        <h2 className='heading'>REGIONAL DISTRIBUTION OF POVERTY</h2>
                        <p>Examining Poverty Disparities in India</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='left-section'>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>Rural vs. Urban Disparity</b> </h6>
                                <p className='paragraph wow fadeInUp'>Poverty is more widespread in rural areas compared to urban centers, mainly due to limited job opportunities and lack of infrastructure.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='left-section'>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>State-wise Analysis</b> </h6>
                                <p className='paragraph wow fadeInUp'>States like Bihar, Uttar Pradesh, and Jharkhand exhibit higher poverty rates attributed to factors such as inadequate access to education and healthcare.</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='left-section'>
                                <h6
                                    className='sub-heading wow fadeInUp'><b>Economic Factors Impact</b> </h6>
                                <p className='paragraph wow fadeInUp'>Regional economic policies and industrial development significantly influence poverty levels, highlighting the need for targeted interventions and inclusive growth strategies. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className='padding-left-right marketing-section'>
                <div className='container-fluid px-0'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>SKILL DEVELOPMENT & ENTREPRENEURSHIP INITIATIVES</h3>
                        <h2 className='heading' style={{ color: '#d77f26' }}>GOVERNMENT INITIATIVES</h2>
                        <p>Driving Skill Development and Entrepreneurship in India</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='left-section' style={{ background: '#17616e', padding: '16px', textAlign: 'center', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff', borderBottom: '2px solid #fff' }}>Skill India Mission</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Aims to train over 40 crore people in various Skils by 2022, enhancing employability and promoting economic growth. </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='left-section' style={{ background: '#6b130f', padding: '16px', textAlign: 'center', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff', borderBottom: '2px solid #fff' }}>Startup India</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Provides extensive support and incentives for new businesses, fostering entrepreneurship and Innovation across the nation. </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='left-section' style={{ background: '#3d1619', padding: '16px', textAlign: 'center', marginBottom: '10px' }}>
                                <h6
                                    className='sub-heading wow fadeInUp' ><b style={{ color: '#fff', borderBottom: '2px solid #fff' }}>National Skill Development Corporation</b> </h6>
                                <p className='paragraph wow fadeInUp' style={{ color: '#fff' }}>Partners with various stakeholders to promote skill development, bridging the gap between demand and supply of skilled labor. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            < Inquiry />

            <section className='achievement-section top bottom'>
                <div className='header-title mb-40px'>
                    {/* <h6 className='sub-heading'>AWESOME PORTFOLIO</h6> */}
                    <h3 className='heading wow fadeInUp' >Get Started With <span style={{ color: '#FFC221' }}>Unnati Skils</span> Now</h3>
                    <p className='paragraph'>Empowering individuals to reach their full potential through relentless pursuit of excellence and unwavering dedication to success.</p>
                    <div class="tp-cta-2-info-list">
                        <span>
                            <span>
                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.6" d="M13 1.01465L4.75 9.26465L1 5.51465" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>
                            Easy to Access
                        </span>
                        <span>
                            <span>
                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.6" d="M13 1.01465L4.75 9.26465L1 5.51465" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>
                            No Credit card
                        </span>
                        <span>
                            <span>
                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.6" d="M13 1.01465L4.75 9.26465L1 5.51465" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>
                            85,000 student onboard with us
                        </span>
                    </div>
                </div>

            </section>

        </>
    )
}
