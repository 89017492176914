import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap'
// import { post, get } from '../pages/helper/helper_api';
// import { toast } from "react-toastify";
import { AvField, AvForm } from 'availity-reactstrap-validation';
// import 'react-toastify/dist/ReactToastify.css';

export default function Signup() {

    const [formData, setFormData] = useState({});
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [isOtp, setIsOtp] = useState(false)

    const form = useRef();

    // const handleValidSubmit = (e, v) => {
    //     const body = {
    //         ...v,
    //         isEmailVerified: isOtp ? true : false
    //     };
    //     if (isOtp) {
    //         post('/verify', body)
    //             .then((res => {
    //                 if (res?.statusCode == 200) {

    //                     toast.success(res?.message);

    //                     form.current.reset();
    //                 } else {
    //                     toast.error(res?.error);
    //                 }
    //             }))
    //             .catch(error => {
    //                 console.log('error while login', error);
    //                 toast.error('Something went wrong');
    //             })
    //     } else {
    //         post('/register', body)
    //             .then((res => {
    //                 if (res?.statusCode == 200) {
    //                     toast.success(res?.message);
    //                     setIsOtp(true)
    //                 } else {
    //                     toast.error(res?.error);
    //                 }
    //             }))
    //             .catch(error => {
    //                 console.log('error while login', error);
    //                 toast.error('Something went wrong');
    //             })
    //     }
    // }

    console.log('isOtp', isOtp)

    return (
        <>
            {/* Contact Us */}
            <section className='bottom top padding-left-right  fontact-us-home'>
                <div className="contact-sec">
                    <div className="container">
                        <div className="row">
                            <div className='center'>
                                <center>
                                    <h2 className='wow fadeInUp heading' style={{ color: '#d80606' }}>Sigh Up</h2>
                                </center>
                            </div>
                            <div className="col-lg-6">
                                <div className='footer-fomr-section'>
                                    <div className='contact-form-footer contact-form'>
                                        {/* <form action="#" className="contFrm" method="POST"> */}
                                        <AvForm ref={form} >
                                            <div classNameName='row'>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">User Name*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                    <AvField name="userName" placeholder="Enter usename" className="inptFld" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Your Email*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                    <AvField name="email" placeholder="example@gmail.com..." className="inptFld" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                <Label className='wow fadeInUp' data-wow-delay="0.4s">Password*</Label>
                                                <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                    {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                    <AvField name="password" type="password" placeholder="Enter password" className="inptFld" validate={{
                                                        required: { value: true, errorMessage: "This field is required!" },
                                                    }} />
                                                </div>
                                                {
                                                    isOtp ?
                                                        <>
                                                            <Label className='wow fadeInUp' data-wow-delay="0.4s">OTP*</Label>
                                                            <div className="col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                                                                {/* <input type="email" placeholder="example@gmail.com" className="inptFld" required /> */}
                                                                <AvField name="otp" type="text" placeholder="Enter password" className="inptFld" validate={{
                                                                    required: { value: true, errorMessage: "This field is required!" },
                                                                }} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <div className="col-12 wow fadeInUp" data-wow-delay="0.8s">
                                                    <button type="submit" name="submit" className="inptBtn hoverButton" >SIGN UP<span>SIGN UP</span></button>
                                                </div>
                                            </div>
                                        </AvForm>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                {/* <img src={require('../assets/images/banner/features/list1/signup.jpg')} class="img-fluid" alt="..." /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
