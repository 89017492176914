
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Parallax, useParallax } from 'react-scroll-parallax';

export default function PrivacyPolicy() {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className='inner-banner'>
                <h1 className='heading'>Sale Agreement</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Sale Agreement </li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po bottom padding-left-right about-section-1 '>
                <div className='container-fluid'>
                    <div className='row'>


                        <h3>
                            Sale Agreement
                        </h3>

                        <p>
                            This agreement (the "Agreement") is entered into on the date [Day/Month/Year],
                            between one party, Mithpan Educa ons LLP
                            (herein shall be the name of the Establishment), by the related party (the "Seller") and _____________ (hereby the name of the
                            Customer), referred to as the "Customer".
                        </p>
                        <p><b>Service Provided by Vendor: </b>Services provided by the Vendor will include education, training, guidance, training of developed capabilities, and offering training
                            in various areas.</p>
                        <p>The Customer accepts all the services provided by the Vendor and fully commits to paying for it. </p>

                        <h3>
                            1. Welcome to the Dialogue:
                        </h3>

                        <ol>


                            <ol type="i">
                                <li>Both the seller and the customer  decide to execute this agreement with enthusiasm and sensitivity, which is important
                                    not only for growth at the individual level, but also for expansion in the business.  </li>
                                <li>This agreement symbolizes a partnership between them that not only encourages innovative and progressive thinking,
                                    but also contributes to the setting of new standards in the field of marketing and training.</li>

                            </ol>
                            <li>
                                This license shall automatically terminate if you violate any of these restrictions and may be terminated by Brand Street Integrated at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                            </li>
                        </ol>

                        <h3>
                            2. Objective:
                        </h3>
                        <p> The main objective of the agreement is to establish a common and permanent relationship between the vendor
                            and the customer for various development programmes. </p>

                        <ul type="i">
                            <li>
                                In addition, this agreement aims to provide the customer with the necessary Skils and knowledge to grow their
                                business.
                            </li>
                        </ul>

                        <h3>
                            3. Predictability:
                        </h3>

                        <ul>
                            <li>
                                3.1.1. Services provided by the vendor may include comprehensive conduct of various development programs, internal
                                training, and strategic construction for business investments.
                            </li>
                            <li>3.1.2. The high quality and sensitivity of these services will help in promoting the identity of the vendor, which is fully
                                capable of achieving this objective.
                            </li>
                        </ul>

                        <h3>
                            4. Presence:
                        </h3>

                        <ul>
                            <li>
                                4.1.1. Attendance is a mandatory prerequisite,  with 97% of the training program  required to attend.
                            </li>
                            <li>4.1.2. If this does not happen, the company/firm will not be responsible for any liability towards any customer.
                            </li>
                        </ul>

                        <h3>
                            5. Terms and Conditions:
                        </h3>

                        <p>
                            <b>5.1.1. Payment Terms: </b>   </p>
                        <ul>
                            <li>5.1.2. It is very important to pay on time. If the payment is not made on time, a  penalty of Rs 100 will be applicable
                                within the  next 24 hours  . If  the payment is not made in the next 36 hours, the agreement will be invalid and there will
                                be no refund amounts.</li>
                        </ul>
                        <p><b>5.1.3. Conclusion of Agreement: </b></p>
                        <ul>
                            <li>5.1.4. At any time, either party may terminate the Agreement with written notice. If the seller terminates the agreement, he
                                will refund the amount by deducting the expenses incurred in training. </li>
                            <li>5.1.5. If the customer terminates the agreement, he will not receive any refund. </li>
                            <li></li>
                        </ul>
                        <p><b>5.1.6. Problem Solving in Service: </b></p>
                        <ul>
                            <li>5.1.7. The Client, if there is a problem with the Service, must fill out a feedback form and submit it to the Company. The
                                management will act on it and provide a solution to the problem.</li>
                        </ul>
                        <p><b>5.1.8. Implementation of Knowledge: </b></p>
                        <ul>
                            <li>5.1.9. Business Guide Our responsibility as instructors is to provide you with the right knowledge. The complete
                                implementation of the plan will be the responsibility of the customer.</li>
                        </ul>
                        <p><b>6. Grievance Redressal:</b></p>
                        <ul>
                            <li>If a complaint needs to be resolved, there should first be an amicable settlement between the parties. If a party is
                                not satisfied,  he can approach the civil court in Gautam Budh Nagar, U ar Pradesh .</li>
                        </ul>
                        <p><b>7. Refund of Amount: </b></p>
                        <ul>
                            <li> The amount will be refunded to the bank account only if the management feels that the amount should be
                                refunded or else after a court order. Management is only responsible for returning the amount that the client has
                                given to management, after all expenses have been deducted. </li>
                            <li> The
                                amount will be refunded to the account only when the customer submits the cancella on cheque, address proof,
                                photo ID, PAN card. Keep in mind that the account must be in the name of the customer who made the payment.
                            </li>
                            <li>. If the financial position of the company/firm becomes weak or unprofitable due to an unstable calamity such as an
                                epidemic, flood, fire, and other natural problem, no amount will be refunded.
                            </li>
                            <li></li>
                        </ul>
                        <p><b>8. Alteration of Rights:</b></p>
                        <ul>
                            <li>Subject to the law of India, the management has the right to make necessary changes to the agreement. The customer will
                                not have any problem on this. </li>
                        </ul>
                        <p><b>9</b> Please send your acceptance of this agreement if you do not send your acceptance of this agreement  within the next 36
                            hours, it will be deemed approved.
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}
