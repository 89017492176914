import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import Inquiry from '../component/Inquiry';
import { Link } from 'react-router-dom';
import Whychooseus from '../component/Whychooseus';
import { get } from "./helper/helper_api";
export default function Team() {
    const swiperRef1 = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        get("/team/list")
            .then(json => {
                console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                    setData(json?.data);
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log('error while getting project list', error);
            })
    }

    console.log("get teams data====>", data);

    return (
        <>
            <section className='inner-banner'>
                <h1 className='heading'>Our Team</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Team</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 top bottom padding-left-right team-section-1'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'> OUR TEAM</h3>
                        <h2 className='heading'>Meet Our <span style={{ color: 'rgb(26, 115, 232)' }}>Teachers</span></h2>
                    </div>
                    <div className='row'>

                        {data.map((item, index) => (
                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <div className='team-profile'>
                                    <div className='profile-box'>
                                        <img src={item?.image} />
                                        <div className='team-social-media'>
                                        </div>
                                    </div>
                                    <h4>{item?.name}</h4>
                                    {/* <h5>{item?.desig}</h5> */}
                                </div>
                            </div>
                        ))
                        }

                    </div>
                </div>
            </section>
            <Inquiry />
        </>
    )
}
